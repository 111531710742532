.Toastify{}
.Toastify .Toastify__toast-container{}
.Toastify .Toastify__toast-container--top-right{
  top:80px;
  right:10px;
}
.Toastify .Toastify__toast-container .Toastify__toast{
  position:relative;
  width:100%;
  height:auto;
  margin:5px 0px;
  animation-delay:0s;
}
.Toastify .xClose{
  position:absolute;
  width:100%;
  top:0px;
  left:0px;
  bottom:0px;
  right:0px;
  text-align: right;
  outline: none;
}

.Toastify--animate-icon {
  display:none;
}

.Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--default{
  display:none;
}
.Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--success{
  background-position: center;
  background: -webkit-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -o-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -ms-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -moz-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: linear-gradient(to right, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
}
.Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--success .xClose span{color:black;}
.Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--error{
  background-color: #313030;
}
.Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--error .xClose span{color:white;}


.Toastify .Toastify__toast-container .Toastify__toast.Toastify__toast--info{
  background-color:white;
}

.Toastify .notificationContainer{
  position:relative;
  width:100%;
  height:175px;
}
.Toastify .notificationContainer .notificationContent{
  position:absolute;
  width:100%;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}

.notificationContent .textContainer{
  width:100%;
  padding:5px;
}
.notificationContent .textContainer p{
  text-align:center;
}

.Toastify__toast.Toastify__toast--error .notificationContent .textContainer p,
.Toastify__toast.Toastify__toast--error .notificationContent .textContainer span{
  color:#fff;
}
.Toastify__toast.Toastify__toast--error .notificationContent .textContainer .againError{
  color:gold;
}

.notificationContent .notification-picGap{
  width:60px;
  height:auto;
  margin:0 auto;
}

.Toastify__toast.Toastify__toast--success .notificationContent  .pet.petRegularSize{
  margin:10px auto 0px auto;
  width: 80px;
  height: 85px;
}

.Toastify__toast.Toastify__toast--success .notificationContent  .pet.petRegularSize .petImg{
  width:100%;
  height:auto;
}

.Toastify__toast.Toastify__toast--error .notificationContent  .pet.petRegularSize{
  margin:10px auto 0px auto;
  width: 80px;
  height: 85px;
}

.Toastify__toast.Toastify__toast--error .notificationContent  .pet.petRegularSize .petImg{
  width: auto;
  height: 100%;
}


.Toastify .Toastify__toast.Toastify__toast--success .notificationProgressBar{
  background: rgba(86,87,90,1);
  background: -moz-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(86,87,90,1)), color-stop(47%, rgba(83,84,86,1)), color-stop(50%, rgba(71,71,73,1)), color-stop(53%, rgba(67,65,66,1)), color-stop(100%, rgba(63,62,63,1)));
  background: -webkit-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: -o-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: -ms-linear-gradient(top, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  background: linear-gradient(to bottom, rgba(86,87,90,1) 0%, rgba(83,84,86,1) 40%, rgba(71,71,73,1) 44%, rgba(67,65,66,1) 48%, rgba(63,62,63,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#56575a', endColorstr='#3f3e3f', GradientType=0 );
}

.Toastify .Toastify__toast.Toastify__toast--error .notificationProgressBar{
  background-position: center;
  background: -webkit-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -o-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -ms-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: -moz-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  background: linear-gradient(to right, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
}


.Toastify .buttonsPanel{
  width:100%;
  margin:10px auto 0px auto;
  height:auto;
  text-align: center;
}
.Toastify .buttonsPanel .imageButton{
  display:inline-block;
  width: 125px;
  height: 38px;
  margin:0px 5px;
}
.Toastify .buttonsPanel .imageButton span{
  color:white;
  font-size:0.9rem;
}

/* Custom animation */
@keyframes slideInUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%) ease-out;
  }
}

.slideInUp {
  animation-name: slideInUp;
  animation-duration: 300ms;
}

.slideOutDown {
  animation-name: slideOutDown;
  animation-duration: 300ms;
}

@media screen and (max-width:480px) {
  .Toastify .Toastify__toast-container{
    max-width: 320px;
    left: auto;
    right:10px;
  }
}
