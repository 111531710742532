.BestExperience{
  /* height:80vh;
  min-height:580px;
  max-height: 580px; */
  background-color:#3f3f3f;
  width:100%;
  background-repeat: no-repeat;
  background-image:url(//mim.cool/assets/web/eatingPeople.svg);
  background-position:bottom;
}

.BestExperience_info {
  position: relative;
  /* overflow: hidden; */
  width: 100%;
  /* height: 100vh !important; */
  /* max-height: 850px; */
  min-height: 700px;
}

.BestExperience_action {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}


.BestExperience .logoContainer{
  max-width:310px;
  width:35%;
  min-width:200px;
  padding: 50px 10px 20px 10px;
  margin: 0 auto;
}

.BestExperience .textContainer h2{
  color:white;
  text-align: center;
  line-height: 1.5rem;
  margin-bottom:5px;
}

@keyframes bgmovementloop {
  0% {background-position: 20% 220px;}
  50% {background-position: 80% 220px;}
  100% {background-position: 20% 220px;}
}

@media (max-width:1500px) {
  .BestExperience{
    --gbackground-color:red;
    background-size:auto 475px;
    animation:none;
  }
}
@media (min-width:1500px) {
  .BestExperience{
    --background-color:yellow;
    background-size:1200px auto;
    animation:none;
  }
}

@media (max-width:900px) {
  .BestExperience {
    animation: bgmovementloop 5s linear  0s infinite;
    --background-color:green;
  }
}

@media (max-width:800px) {
  .BestExperience {
    background-size:1000px auto;
    animation: bgmovementloop 5s linear  0s infinite;
    --background-color:orange;
  }
  .BestExperience_info {
    min-height: unset;
  }
  .BestExperience_image {
    position: relative;
    width: 100%;
    height: 200px;
  }
}

@media (max-width:350px) {
  .BestExperience .textContainer h2{
    font-size: 1.30rem;
    line-height: 1.30rem;
  }
}
