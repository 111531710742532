.TicketSummary {
  &.fixed{
    position: fixed;
    bottom:0px;
    left:0px;
    width:100%;
    z-index:10;
  }
  &.relative{
    position:relative;
  }
  padding-bottom:20px;
  background-color:white;
  -webkit-box-shadow: 0px -15px 30px -10px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px -15px 30px -10px rgba(0,0,0,0.3);
  box-shadow: 0px -15px 30px -10px rgba(0,0,0,0.3);
  .typeSummary,
  .totalSum {
    width:100%;
    height:30px;
    padding: 5px 10px 5px 10px;
    background-color:black;
    margin-bottom:5px;
    text-align: center;
  }
  .totalSum{
    background-position: center;
    background: -webkit-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
    background: -o-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
    background: -ms-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
    background: -moz-linear-gradient(left, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
    background: linear-gradient(to right, rgb(232, 174, 107) 0%, rgb(250, 208, 66) 100%);
  }
  .typeSummary span{
    color:rgb(250, 208, 66);
    padding:0px 5px 0px 5px;
    text-transform: uppercase;
  }
  .totalSum p{
    color:black;
    text-transform: uppercase;
  }
}
