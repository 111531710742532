.AnimationCarouselle {
  position:relative;
  width: 100%;
  height: 50vh;
  aspect-ratio: 1.51;
  overflow:hidden;
}

.AnimationCarouselle .middleOfCarouselleAnimation{
  position:absolute;
  width:50%;
  min-width: 300px;
  top:50%;
  left:50%;
  z-index:9;
  transform:translate(-50%, -50%);
}
.AnimationCarouselle .middleOfCarouselleAnimation button{
  // min-width:250px;
  max-width:300px;
  width:50%;
  margin-top:15px
}

.AnimationCarouselle .InfiniteLandscapeDiv{
  position:absolute;
  height: 450px;
  width: 100%;
  z-index: 0;
  bottom: 0px;
  background-position: 0px 0px;
  background-size: auto 100%;
  background-repeat: repeat-x;
  background-image:url(//mim.cool/assets/web/landscape1.svg);
  animation: movementloop 40s linear  0s infinite;
}

.AnimationCarouselle .InfiniteLandscapeDiv.normal{
  animation-direction: normal;
  -webkit-animation-direction: normal;

}
.AnimationCarouselle .InfiniteLandscapeDiv.reverse{
  animation-direction: reverse;
  -webkit-animation-direction: reverse;
}

.AnimationCarouselle .pet.petRegularSize{
  margin:15px auto 0px auto;
  width: 300px;
  height: 205px;
  perspective: 1000px;
  transition: 1s;
	transform-style: preserve-3d;
}

.AnimationCarouselle .pet.petRegularSize .petImg{
  width:100%;
  height:auto;
}

.AnimationCarouselle .flying-right .pet.petRegularSize {
  /*-webkit-transform: scaleX(-1);
   transform: scaleX(-1);*/
   transform: rotateY(-180deg);
}

.AnimationCarouselle .flying-left .pet.petRegularSize {
  /*-webkit-transform: scaleX(-1);
   transform: scaleX(-1);*/
   transform: rotateY(0deg);
}


@keyframes movementloop {
  0% {background-position: 4152px 0px;}
  100% {background-position: 0px 0px;}
}
@-webkit-keyframes movementloop {
  0% { background-position: 4152px 0px;}
  100% {background-position: 0px 0px;}
}
@keyframes movementloop1 {
  0% {background-position: 3229px 0px;}
  100% {background-position: 0px 0px;}
}
@-webkit-keyframes movementloop1 {
  0% { background-position: 3229px 0px;}
  100% {background-position: 0px 0px;}
}
@keyframes movementloop2 {
  0% {background-position: 2768px 0px;}
  100% {background-position: 0px 0px;}
}
@-webkit-keyframes movementloop2 {
  0% { background-position: 2768px 0px;}
  100% {background-position: 0px 0px;}
}
@keyframes movementloop3 {
  0% {background-position: 2584px 0px;}
  100% {background-position: 0px 0px;}
}
@-webkit-keyframes movementloop3 {
  0% { background-position: 2584px 0px;}
  100% {background-position: 0px 0px;}
}
@keyframes movementloop4 {
  0% { background-position: 2307px 0px;}
  100% {background-position: 0px 0px;}
}
@-webkit-keyframes movementloop4 {
  0% { background-position: 2307px 0px;}
  100% {background-position: 0px 0px;}
}

@media (max-width:850px) {
  .AnimationCarouselle .pet.petRegularSize{
    width: 275px;
  }
  .AnimationCarouselle .InfiniteLandscapeDiv{
    height: 350px;
    animation: movementloop1 40s linear  0s infinite;
  }
  .AnimationCarouselle .middleOfCarouselleAnimation{
    top: unset;
    transform:translate(-50%);
  }
  .AnimationCarouselle .middleOfCarouselleAnimation button{
    max-width: unset;
    width:100%;
  }
}

@media (max-width:750px) {
  .AnimationCarouselle .pet.petRegularSize{
    width: 250px;
  }
  .AnimationCarouselle .InfiniteLandscapeDiv{
    height: 300px;
    animation: movementloop2 40s linear  0s infinite;
  }
}

@media (max-width:500px) {
  .AnimationCarouselle .pet.petRegularSize{
    width: 235px;
  }
  .AnimationCarouselle .InfiniteLandscapeDiv{
    height: 280px;
    animation: movementloop3 40s linear  0s infinite;
  }
}


@media (max-width:450px) {
  .AnimationCarouselle .pet.petRegularSize{
    width: 215px;
  }
  .AnimationCarouselle .InfiniteLandscapeDiv{
    height: 250px;
    animation: movementloop4 40s linear  0s infinite;
  }
}

@media (max-width:400px) {
  .AnimationCarouselle .pet.petRegularSize{
    width: 200px;
  }
  .AnimationCarouselle .InfiniteLandscapeDiv{
    height: 240px;
  }
}
