.sed {
  height: 100vh;
}

.sed.normal{
  animation-direction: normal;
  -webkit-animation-direction: normal;
  animation-fill-mode: both;
  -webkit-animation-fill-mode:both;
}
.sed.reverse{
  animation-direction: reverse;
  -webkit-animation-direction: reverse;
  animation-fill-mode: both;
  -webkit-animation-fill-mode:both;
}