.btn-unstyled {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  cursor: pointer;
}

.btn-solid {
  border: 1px solid #000000;
  display: block;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  position: relative;
  margin: 0 auto;
  outline: none;
  cursor: pointer;
  padding: 0.5rem;
}

.btn-theme-default {
  border: 1px solid #3f3f3f;
  background-color: #ffffff;
  span {
    color: #3f3f3f;
  }
}

.btn-theme-reverse {
  border: 1px solid #3f3f3f;
  background-color: #3f3f3f;
  span {
    color: #ffffff;
  }
}
