.container{
  // max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  // padding: 2rem;
}
.blackLine{
    border-bottom:1px solid black;
    width:40px;
    height:1px;
    margin:0 auto;
    margin-bottom:15px;
}
.knowButton {
  span {
    font-size: 1.2rem;
  }
}

.tryButton {
  span {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 768px) {
  .knowButton {
    span {
      font-size: 0.8rem;
    }
  }
  
  .tryButton {
    span {
      font-size: 1.6rem;
    }
  }
}