.container.SearchBar {
  position:fixed;
  top:39px;
  height: auto;
  -webkit-box-shadow: 0px 15px 30px -10px rgba(0,0,0,3);
  -moz-box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.3);
  box-shadow: 0px 15px 30px -10px rgba(0,0,0,0.3);
  background-color:white;
  margin-bottom: 20px;
  z-index:99;
}

.SearchBar {
  .stringSearcher {
    position: relative;
    text-transform: uppercase;
    height: 39px;
    input{
      position: relative;
      right: 0px;
      top: 0px;
      height:39px;
      width:100%;
      border:none;
      padding:0px 10px;
      font-size:1.05rem;
      outline:none;
    }
    .backBap{
      position: absolute;
      width:20px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      .back {
        height:18px;
        width:auto;
      }
    }
  }
}


.SearchBar .allergenSection {
  background-color:#3f3f3f;
  display:flex;
  position: relative;
  padding: 15px 10px 10px 10px;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height:39px;
  cursor:pointer;
  .chevron{
    position:relative;
    width:35px;
    padding:5px 0px;
    display: flex;
    flex-direction:row;
    justify-content: center;
    img {
      position:absolute;
      width:20px;
      height:auto;
      &.close{
        top:50%;
        transform:translate(0%, -50%) rotate(90deg);
      }
    }
  }
  .ingredientSelected{
    position: relative;
    flex-grow:1;
    text-align:center;
    width: calc(100% - 200px);
    .ingredientSlider{
      position: relative;
      height:auto;
      .slick-dots{
        position:relative;
        top:-20px;
        height:5px;
        li {
          width:5px;
          height:5px;
          button{
            width: 5px;
            height: 5px;
          }
          button:before {
            width: 5px;
            height: 5px;
            color:white;
          }
        }
      }
    }
    .chevron{
      position: absolute;
    }
    .TextDisplayer{
      display:none;
    }
  }
  .noAllergensTitle{
    position:relative;
    flex-grow: 1;
    padding:5px 0px 5px 0px;
    p, span{
      line-height:10px;
      color:white;
      font-size: 0.95rem;
      text-align:center;
      text-transform:none;
    }
  }
  .chevron-down{
      margin-top:5px;
      width:20px;
      height:auto;
      &.close{
        transform: rotate(90deg);
      }
    }
  .allergenContainer{
    .descriptionTitle{
      width:100%;
      max-width:360px;
      padding:5px 10px;
      text-align: center;
      margin: 0 auto;
      span, p{
        font-size: 0.95rem;
        color:white;
      }
    }
    .ingredientContainer{
      width:100%;
      max-width:360px;
      margin:0 auto;
      display:flex;
      justify-content: space-around;
      align-items: flex-start;
      flex-wrap: wrap;
      .Ingredient{
        outline:none;
        width:60px;
        margin:5px;
        .ingredientName{
          color:white;
          margin-top: 2px;
          text-align: center;
          display: block;
          &.no-moving{
            width:100%
          }
        }
      }
    }
    .acceptAllergens{
        width:100%;
        max-width:260px;
        margin:20px auto;
        text-transform: uppercase;
        span {
          font-family: 'MimBoldItalic' !important
        }
      }
  }
}
