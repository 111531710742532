.pet{
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.petRegularSize{
  width:75%;
  max-width: 320px;
  height:320px;
}

.pet .petImg {
  position: absolute;

}

.pet .petForReferenceSize{
  height:auto;
  opacity:0;
}


.pet .petImg.fullOpacity{
  opacity:1;
}

.pet .petImg.noOpacity{
  opacity:0;
}
