.Ingredient{
  display:inline-block;
  outline:none;
  .medium{
    position:relative;
    width:60px;
    height:40px;
    margin: 0 auto;
  }
  .picture{
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    animation-delay:0s;
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    &.show{
      -webkit-animation-name: fadeIn;
      animation-name: fadeIn;
    }
    &.noShow{
      -webkit-animation-name: fadeOut;
      animation-name: fadeOut;
    }
  }
  .picture.medium{
    width:40px;
    height:40px;
  }
  .ingredientName{
    span {
      color:white;
      text-align: center;
    }
  }
  .TextDisplayer{
    height:20px;
  }
}


@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@-webkit-keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

@keyframes fadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}